export const environment = {
  production: false,
  isLocalDev: false,
  oneTrustDomainId: 'dc6a175e-98b5-41ac-a2fe-873bf94dcf8b',
  oneTrustCookieDomains: ['.acadvantage.acacceptance.com', '.acacceptance.com'],
  oneTrustTestMode: true,
  fullStoryEnabled: false,
  fullStoryOrgId: '125F9D',
  authUrl: 'https://acadvantage.qa.acacceptance.com/auth',
  sharedApiUrl: 'https://acadvantage-shared-api.qa.acacceptance.com/api/v1.0',
  rehashApiUrl: 'https://acadvantage-rehash-api.qa.acacceptance.com/api/v1.0',
  preverificationApiUrl: 'https://preverificationapi.svc.qa.acacceptance.com',
  authApiUrl: 'https://acadvantageauth.qa.svc.acacceptance.com',
  dreamsApiUrl: 'https://dreams.svc.qa.acacceptance.com/api/v1.0',
  signInUrl:
    'https://acadvantage-qa.auth.us-east-1.amazoncognito.com/oauth2/authorize?response_type=code&client_id=1m32v04n4qujh8d2i89olkhfcf&redirect_uri=https://acadvantage.qa.acacceptance.com/auth',
  defaultInternalUserDealerNo: 28635,
  remotes: {
    preverification: 'https://acadvantage-preverification.qa.acacceptance.com',
    rehash: 'https://acadvantage-rehash.qa.acacceptance.com',
    dreams: 'https://acadvantage-dreams.qa.acacceptance.com',
    dashboard: 'https://acadvantage-dashboard.qa.acacceptance.com'
  }
};
